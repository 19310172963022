<template>
  <div class="ProfileIndex">
    <swiper :banners="banners"></swiper>
    <breadcrumb v-if="clientWidth < 769" :refer="refer"></breadcrumb>
    <div class="Profiles flex" v-if="Profile.pictureUrl">
      <div class="profiles">
        <div class="profile0" :class="Profile.vip ? 'Profiles-bg' : ''">
          <div class="head-portrait">
            <img :src="Profile.pictureUrl" width="100%">
          </div>
          <div v-if="Profile.vip" class="head-cord">
            <img src="../../assets/PC/profile/viphead.png" alt="">
          </div>
          <div class="head-switch head-portrait cur" @click="switches">
            <div class="switch tc">切换</div>
          </div>
          <div class="head-name">
            <h1 class="tc" :title="Profile.name">{{Profile.name}}</h1>
            <div class="badges tc cur" @click="LevelDetail">
              <img v-if="this.clientWidth > 750" :src="Profile.level.web_bck">
              <img v-else :src="Profile.level.app_bck">
              <div class="badge-name">{{Profile.level.levelName}}</div>
            </div>
            <p class="golden violet">金币可用于兑换礼品，详情敬请关注官网。</p>
          </div>
          <div class="balance">
            <div class="gold flex2" title="我的金币可用于兑换礼品，兑换详情敬请关注官网。">
              <img src="@/assets/PC/profile/gold.png" alt="">
              <span v-if="Profile.money > 9999">{{ Math.floor(Profile.money / 10000) }}w</span>
              <span v-else>{{Profile.money}}</span>
              <span>金币</span>
            </div>
            <div class="integral flex2">
              <img src="@/assets/PC/profile/1.png" alt="">
              <span v-if="Profile.integral > 9999">{{ Math.floor(Profile.integral / 10000) }}w</span>
              <span v-else>{{Profile.integral}}</span>
              <span>积分</span>
            </div>
          </div>
        </div>
        <div class="Profile-nav">
          <ul>
            <li class="Profile-nav-item flex0 cur" v-for="item in ProfileNav" :key="item.id" :class="item.route == $route.path ? 'active1':''" @click="navRoute(item.route)">
              <img v-if="item.route != $route.path" class="Profile-nav-item-img" :src="item.imgUrl" alt="">
              <img v-else class="Profile-nav-item-img" :src="item.img1Url" alt="">
              <span class="Profile-nav-item-name">{{item.name}}</span>
            </li>
          </ul>
        </div>
      </div>
      <div style="width:100%">
        <router-view />
      </div>
    </div>
    <div v-if="switchHead" class="switchShade flex0">
      <div class="switchHead animate__animated animate__zoomIn">
        <div class="shadeHead flex3">
          <span>头像切换</span>
          <span class="cur" @click="closeShade"><i class="el-icon-circle-close"></i></span>
        </div>
        <div class="shadeContent">
          <h1 class="tc">默认头像</h1>
          <h2></h2>
          <div class="heads flex1">
            <div v-for="(item, index) in headImg" :key="index" class="heads-item flex0">
              <div class="heads-item-img cur" :class="item.isNowHeadImg ? 'heads-item-img-active' : ''" @click="switchImg(index)">
                <img class="img1" v-if="!item.isNowHeadImg" :src="item.imgUrl" alt="" width="100%">
                <img class="img2"  v-else  :src="item.imgUrl_Selected" alt="" width="100%">
                <img v-if="item.isNowHeadImg" class="headChecked" src="@/assets/PC/profile/headChecked.png" alt="">
              </div>
            </div>
          </div>
        </div>
        <div class="shadeBtn flex0">
          <el-button type="info" size="medium" @click="saveHead" :disabled = "openIsDisabled" style="width: 25%;margin-right: 4vw" >确定</el-button>
          <el-button type="info" size="medium" @click="closeShade" style="width: 25%">取消</el-button>
        </div>
      </div>
    </div>
    <div v-if="switchHead0" class="switchShade flex0">
      <div class="switchHead switchHead1 animate__animated animate__zoomIn">
        <div class="shadeHead flex3 levelText">
          <img src="../../assets/PC/profile/titleBg.png" alt="">
          <span>{{Profile.level.levelName}}</span>
          <span class="cur" @click="closeShade"><i class="el-icon-circle-close"></i></span>
        </div>
        <div class="leave-Detail flex6">
          <div class="leave-Detail-l">
            <h1 v-for="(item,index) in LevelList" :key="index">
              <span v-if="item.levelName == Profile.level.levelName">
                距离下一等级还有
                <b v-if="index+1 < LevelList.length"> {{ LevelList[index+1].start - Profile.integral}}</b>
                <b v-else> {{ LevelList[index].end - Profile.integral}} </b> 积分
              </span>
            </h1>
            <ul>
              <li v-for="(item,index) in LevelList" :key="index">
                {{item.start}}-{{item.end}} 积分，用户等级为 "{{item.levelName}}"
              </li>
            </ul>
          </div>
          <div class="leave-Detail-r">
            <img src="../../assets/PC/profile/sub.png" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import swiper from "@/components/Swiper.vue";
import { GetBanner } from '@/api/Home.js'
import { debounce, Update_Info_gold,GetWxShares } from "@/utils/common";
import breadcrumb from "@/components/Breadcrumb/index.vue";
import * as profile from "@/api/profile.js";

export default {
  name: "Profile",
  data(){
    return{
      // !banner图
      banners: [],
      // !面包屑
      refer: [{name: "首页",href: "/"}, {name: "个人中心"}],
      // !个人中心数据
      Profile: this.$store.state.Infos,
      // !等级信息
      LevelList:[],
      ProfileNav:[
        {name:'个人信息', id: '0', imgUrl: require('@/assets/PC/profile/information.png'), img1Url: require('@/assets/PC/profile/information1.png'), route:'/Home/Profile/Information'},
        {name:'修改密码', id: '1', imgUrl: require('@/assets/PC/profile/reset.png'), img1Url: require('@/assets/PC/profile/reset1.png'), route:'/Home/Profile/Reset'},
        {name:'我的收藏', id: '2', imgUrl: require('@/assets/PC/profile/collect.png'), img1Url: require('@/assets/PC/profile/collect1.png'), route:'/Home/Profile/myCollect'},
        {name:'我的报名', id: '3', imgUrl: require('@/assets/PC/profile/apply.png'), img1Url: require('@/assets/PC/profile/apply1.png'), route:'/Home/Profile/MyRegistration'},
        {name:'我的勋章', id: '4', imgUrl: require('@/assets/PC/profile/badge.png'), img1Url: require('@/assets/PC/profile/badge1.png'), route:'/Home/Profile/badge'},
        {name:'兑换记录', id: '5', imgUrl: require('@/assets/PC/profile/conversion.png'), img1Url: require('@/assets/PC/profile/conversion1.png'), route:'/Home/Profile/ForRecord'},
        {name:'中奖记录', id: '6', imgUrl: require('@/assets/PC/profile/gift.png'), img1Url: require('@/assets/PC/profile/gift1.png'), route:'/Home/Profile/DrawRecord'},
        {name:'任务中心', id: '7', imgUrl: require('@/assets/PC/profile/integral.png'), img1Url: require('@/assets/PC/profile/integral1.png'), route:'/Home/Profile/taskCenter'},
      ],                                                     // !左侧导航
      switchHead: false,                                     // !切换弹窗显示
      switchHead0: false,                                    // !等级介绍弹窗
      headImg:[],                                            // !默认头像
      openIsDisabled: true,                                  // !保存按钮状态
      openIsDisabledIndex: '',                               // !选中头像的保存按钮状态
    }
  },
  mounted(){
    const share = {
      title: '个人中心 | 剑盟社区',
      desc: ''
    }
    GetWxShares(share);
    if(this.$store.state.token){
      // !获取等级信息
      profile.GetLevelList().then(res => {
        this.LevelList = res.data;
      })
    }else{
      this.$router.push('/');
    }
    Update_Info_gold()
    // !获取banner图
    GetBanner().then(res=>{
      if(this.clientWidth > 750){
        this.banners = res.data.personal;
      }else{
        this.banners = res.data.personal_m;
      }
    })
  },
  methods:{
    // !路由切换
    navRoute(route){
      this.$router.push(route);
    },
    // !切换头像弹框
    switches(){
      // 获取默认头像
      profile.DefaultHeadImg().then(res=>{
        this.headImg = res.data
        this.headImg.filter( (item, ind) => {
          if(item.isNowHeadImg){
            this.openIsDisabledIndex = ind;
          }
        })
      })
      this.switchHead = true;
      this.openIsDisabled = true
    },
    // !关闭头像弹窗
    closeShade(){
      this.switchHead = false;
      this.switchHead0 = false;
      this.openIsDisabledIndex = ''
    },
    // !点击切换头像
    switchImg(index){
      this.headImg.filter( (item, ind) => {
        if(index == ind){
          item.isNowHeadImg = true
        }else{
          item.isNowHeadImg = false
        }
      })
      if(index != this.openIsDisabledIndex){
        this.openIsDisabled = false
      }else{
        this.openIsDisabled = true
      }
      this.headImg[index].isNowHeadImg = true
    },
    // !保存头像
    saveHead(){
      let data = {};
      this.headImg.filter( (item) => {
        if(item.isNowHeadImg){
          data.headImgUrl = item.imgUrl_Selected;
        }
      })
      debounce(()=>{
        profile.ChangeHeadImg(data).then(res=>{
          if(res.status == 1){
            this.$toast.success({message:"保存成功！"});
            this.switchHead = false;
            profile.GetUserInfo().then(res => {// !获取个人信息
              this.Profile = res.data;
              this.$store.dispatch('Profile', res.data);
            }).then( ()=> {
              profile.GetSystemMessageTop4().then(res => {
                if(res.status == 1) {
                  this.$store.dispatch('MsgTop4', res.data);
                }
              });
            })
          }else{
            this.$toast.fail(res.message)
          }
        })
      },1500)
    },
    // !等级介绍弹窗
    LevelDetail(){
      this.switchHead0 = true;
    }
  },
  computed: {
    clientWidth() {
      return this.$store.state.clientWidth;
    },
    Infos() {
      return this.$store.state.Infos;
    }
  },
  watch: {
    Infos(val){
      this.Profile = val;
    }
  },
  components:{
    breadcrumb,
    swiper
  }
}
</script>
<style lang="scss" scoped>
  $mobil_base: 750;
  @function vw($true) {
    @return ($true / $mobil_base) * 100vw;
  }
  .ProfileIndex {min-height: 100vh;padding-bottom: 100px;background: #F2F5F7;position: relative;
    .crumb { margin: 0 vw(30) }
    .Profiles {
      margin: 0 auto;
      max-width: 1200px;
      min-height: 440px;
      background: #fff;
      margin-top: 40px;
      border-radius: 5px;
      overflow: hidden;
      box-shadow: 0 0 10px #ccc
    }
    .profiles {width: 223px;min-height: 100%;border-right: 1px #f8f8f8 solid;}
    .Profiles-bg {background: url('~@/assets/PC/profile/vipbg.png') no-repeat; background-size: cover;}

    .profile0 {height:245px;border-bottom: 1px #f8f8f8 solid; position: relative; padding-top: 25px;}
    .head-portrait {width: 104px;height: 104px;border-radius: 50%;margin: 0 59px 13px 60px;overflow: hidden;position: relative;z-index:2}
    .head-cord {position: absolute; top: 18px; left: 46px; z-index: 2;}
    .head-cord img {width: 125px;}
    .head-switch {position: absolute; top: 25px;}
    .switch{width: 100%;height: 25px;line-height: 25px;background: rgba(0, 0, 0, 0.3);position: absolute;left: 0;bottom: 0;color: #fff;}

    .head-name {height: 70px;}
    .head-name h1 {margin-bottom: 10px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;padding: 0 20px;font-size: 16px}
    .badges {position: relative;}
    .badges .badge-name{position: absolute;left: 96px;top: 5px;color: #fff;}
    .golden {display: none;}

    .balance{display: flex;justify-content: space-around;}
    .balance img{width: 19px;height: 19px;margin-right: 5px;}

    .Profile-nav {width: 100%;padding: 10px 0;}
    .Profile-nav-item {width: 100%;height: 59px;border-left: 3px #fff solid;}
    .Profile-nav-item-img {width: 20px;height: 20px;margin-right: 5px;}

    .switchShade{width: 100%;height: 100%;position: fixed;left: 0;top: 0;background: rgba(0, 0, 0, 0.3);z-index: 100;}
    .switchHead {width: 836px;height: 400px;box-shadow: 0px 2px 4px 0px rgba(96, 96, 96, 0.15);background: #fff;}
    .switchHead1 {width: 500px;height: auto}
    .shadeHead {height: 50px;padding: 0 25px;font-size: 15px;}
    .shadeHead .el-icon-circle-close{font-size: 24px;}

    .shadeContent h1 {font-size: 17px;color: #6b18a8;margin: 15px 0;}
    .shadeContent h2 {width: 108px;height: 2px;background: #6b18a8;margin: 0 auto;}
    .heads {padding: 25px 0;flex-wrap: wrap;}
    .heads-item{width: 139px;height: 139px;}
    .heads-item-img{width: 104px; height: 104px; border-radius: 50%;}
    .heads-item-img-active{box-shadow: 0 0 20px #999;position: relative;}
    .heads-item-img-active .headChecked{width: 30px;height: 30px;position: absolute;left: 0; top: 0;}

    .levelText {position: relative;}
    .levelText img {position: absolute;left: 0;top: -7px;width: 111px;height: 36px;z-index: -1;}
    .levelText span:nth-child(2) {color: #fff;margin: -25px 0 0 -10px;font-weight: bold;}

    .leave-Detail {padding: 0 20px 20px 20px;}
    .leave-Detail-r {width: 84px;height: 84px}
    .leave-Detail h1 span {font-size: 18px; margin-bottom: 10px}
    .leave-Detail ul li {line-height: 2;font-size: 16px;}

  }


  @media (max-width: 750px) {
    .ProfileIndex{ padding-bottom: vw(60);
      .Profiles {display: block; width: calc(100% - 8vw); margin: 0 auto;}
      .profiles {width: 100%;}
      .Profiles-bg {background: url('~@/assets/PC/profile/vipbgm.png') no-repeat; background-size: cover;}
      .profile0 {width: 100%;height: vw(208);display: flex; padding-top: 0;}

      .head-portrait{min-width: vw(120);min-height: vw(120);width: vw(120);;height: vw(120);margin: vw(41) vw(14) vw(47) vw(43);}
      .head-cord {top: vw(34); left: vw(28);}
      .head-cord img {width: vw(144);}
      .head-switch {top: 0;}
      .switch{height: vw(30);line-height: vw(30);font-size: vw(24);}
      .head-name {width: vw(200);}
      .head-name h1 {margin: vw(59) 0 vw(20) 0;height: vw(34);font-size: vw(28);text-align: left;width: vw(380);padding: 0;margin-left: vw(17);}
      .head-name img {width: vw(170);height: vw(50);}

      .badges .badge-name{position: absolute;left: vw(67.5);top: vw(8);color: #fff;font-size: vw(24);white-space: nowrap;}
      .balance {width: 100%;padding-top: vw(70);}
      .balance img{width: vw(30);height: vw(30);margin-right: 2px;}
      .golden {display: block;white-space: nowrap;font-size: vw(24);margin-left: vw(17);}

      .Profile-nav{width: 100%;height: vw(104);padding-top:0;border-bottom: 1px #f8f8f8 solid;}
      .Profile-nav ul {display: flex;overflow-x: auto;}
      .Profile-nav-item {min-width: 22.4%;;height: vw(104);display: block;text-align: center;border-left: 0;}
      .Profile-nav-item img {display: block;margin: 0 auto;width: vw(28);height: vw(28);margin-top: vw(20);}
      .Profile-nav-item span,.balance span{font-size: vw(24)!important;}

      .shadeHead {padding: 0 vw(30);}
      .switchHead{width: vw(677);height: vw(770);}
      .heads{padding-top: 0;}
      .heads-item{width: vw(337.5);height: vw(225);}
      .heads-item-img{width: vw(150); height: vw(150); border-radius: 50%;}
      .shadeContent h1,.shadeContent h2 {display: none;}
      .heads-item-img-active .headChecked{width: vw(44);height: vw(44);}

      .leave-Detail {padding: 0 vw(20) vw(30) vw(20)}
      .leave-Detail-r img{width: vw(126);height: vw(126)}
      .leave-Detail h1 span {font-size: vw(24);margin-bottom: vw(10);}
      .leave-Detail ul li {line-height: 2;font-size: vw(24);}
      .switchHead1 {height: auto}

      ::-webkit-scrollbar {
        display: none; /* Chrome Safari */
      }
    }
  }
</style>